import React, { Component } from "react";
import Chart from "chart.js";
import "chartjs-plugin-labels";

import { randomColorArray, tooltips, findMaxTicks } from "../functions/chartHelpers";

export default class BarChart extends Component {
  chartRef = React.createRef();
  myChart;

  componentDidMount() {
    if (this.props.values) this.buildChart();
  }

  componentDidUpdate() {
    if (this.props.values) this.updateChart();
  }

  updateChart = () => {
    const maxValue = Math.max(...this.props.values);
    const maxTicks = findMaxTicks(maxValue);

    this.myChart.data.labels = this.props.labels;
    this.myChart.data.datasets[0].data = this.props.values;
    this.myChart.options.scales.yAxes[0].ticks.suggestedMax = maxTicks;

    this.myChart.update();
  }

  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext("2d");

    Chart.defaults.global.defaultFontFamily = "sans-serif";

    const maxValue = Math.max(...this.props.values);
    const maxTicks = findMaxTicks(maxValue);

    this.myChart = new Chart(myChartRef, {
      type: "bar", //bar, horizontalBar, pie, line, doughnut, radar, polarArea
      data: {
        labels: this.props.labels,
        datasets: [
          {
            label: this.props.dataLabelType,
            data: this.props.values,
            backgroundColor: randomColorArray,
            hoverBorderColor: "rgba(0,0,0,0.8)",
            hoverBorderWidth: 2
          }
        ]
      },
      options: {
        animation: false,
        aspectRatio: this.props.aspectRatio || 2,
        responsive: true,
        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMax: maxTicks,
                callback: value => this.props.dataLabelType + value
              }
            }
          ]
        },
        legend: {
          display: false,
        },
        tooltips,
        plugins: {
          labels: {
            render: "value",
            fontSize: 14,
            fontStyle: "bold",
            fontColor: "#000000",
            fontFamily: '"Lucida Console", Monaco, monospace'
          }
        }
      }
    });
  };

  render() {
    return <canvas id={this.props.id} ref={this.chartRef} />;
  }
}
