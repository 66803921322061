import React, { Component } from "react";

class Sends extends Component {
  state = {
    sortType: 'sendTime',
    sortDirection: 'descending',
  };

  setSort = ({ target }) => {
    const sortType = target.getAttribute('data-type');
    const sortDirection = target.getAttribute('data-direction');
    this.setState({ sortType, sortDirection });
  };

  sortFunction = (a, b) => {
    const sortDirection = this.state.sortDirection;

    const dateA = new Date(a.startdate).getTime();
    const dateB = new Date(b.startdate).getTime();

    if (dateA > dateB) {
      return sortDirection === 'ascending' ? 1 : -1;
    } else {
      return sortDirection === 'ascending' ? -1 : 1;
    }
  };

  render() {
    const {
      error,
      data,
      isLoaded,
      sendDate,
      onSendDateChange,
      sendType,
      sendName,
      sendStart,
    } = this.props;

    const { sortType, sortDirection } = this.state;

    const dateOptions = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    if (error) {
      return (
        <div className="mx-auto wrapper error">Error: {error.message}</div>
      );
    } else if (!isLoaded) {
      return (
        <div className="loading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    } else {
      const sortedData = data.sends ? [...data.sends]: [];
      sortedData.sort(this.sortFunction);
      
      return (
        <div className="wrapper home sends">
          <nav className="flex items-center sendSearch">
            <span role="img" aria-label="search icon" className="hidden lg:block sendImg">
              <svg style={{ verticalAlign: 'sub' }} width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"/></svg>
            </span>
            <select name="sendType" onChange={onSendDateChange}>
              <option value="created_after">Created After</option>
              {/* <option value="start_date">Start Date</option> */}
              <option value="name">Send Name</option>
            </select>
            {sendType === "created_after" && (
              <label className="flex items-center">
                <input
                  autoFocus
                  type="date"
                  className="searchInput"
                  name="sendDate"
                  value={sendDate}
                  onChange={onSendDateChange}
                  min="2022-01-01"
                />
              </label>
            )}
            {sendType === "start_date" && (
              <label className="flex items-center">
                <input
                  autoFocus
                  type="date"
                  className="searchInput"
                  name="sendStart"
                  value={sendStart}
                  onChange={onSendDateChange}
                  min="2022-01-01"
                />
              </label>
            )}
            {sendType === "name" && (
              <label className="flex items-center">
                <input
                  autoFocus
                  type="text"
                  className="searchInput sendNameInput"
                  name="sendName"
                  value={sendName}
                  onChange={onSendDateChange}
                />
              </label>
            )}
          </nav>
  
          <p><b>These emails have or will go out.</b> Data reflects whats in our email system. <br />Subject Lines and From Names at this view are updated one minute past the hour, every hour.</p>
         
        {
          data.topFromLines && data.topFromLines.length > 0 &&
          <div className="mostUsed flex justify-between">
            <div>
              <h2>Most Used Subject Line</h2> 
              {data.topSubjectLines.length > 0
                ? data.topSubjectLines.map(item => <p key={item[0]}>{item[0]}</p>)
                : <p>No duplicate subject lines found</p>
              }
            </div>
            <div>
              <h2>Most Used From Line</h2> 
              {data.topFromLines.map(item => (
                <p key={item[0]}>{item[0]}</p>
              ))}
            </div>
          </div>
         }

          <div className="tableWrapper">
            <table id="promocodeList">
              <thead>
                <tr>
                  <th>
                    Send Time
                    <div className="buttonWrapper">
                      <button
                        disabled={
                          sortType === "sendTime" &&
                          sortDirection === "ascending"
                        }
                        className="upArrow"
                        data-type="sendTime"
                        data-direction="ascending"
                        onClick={this.setSort}
                      ></button>
                      <button
                        disabled={
                          sortType === "sendTime" &&
                          sortDirection === "descending"
                        }
                        className="downArrow"
                        data-type="sendTime"
                        data-direction="descending"
                        onClick={this.setSort}
                      ></button>
                    </div>
                  </th>
                  <th>Name</th>
                  <th>Subject Line</th>
                  <th>From Name</th>
                  <th>Segment</th>
                </tr>
              </thead>
              <tbody>
                {sortedData &&
                  sortedData.map((item) => (
                    <tr key={item.uuid} className={item.uuid}>
                      <td>
                        {new Date(item.startdate).toLocaleString(
                          'en-US',
                          dateOptions
                        )}
                      </td>
                      <td>
                        <a href={`/sendTemplate/${item.uuid}/`}>
                          {item.name}
                        </a>
                      </td>
                      <td>{item.subject_line}</td>
                      <td>{item.from_name}</td>
                      <td>{item.segment_name}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
}
export default Sends;
