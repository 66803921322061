import React, { Component } from "react";
import Chart from "chart.js";

import { tooltips, findMaxTicks } from "../functions/chartHelpers";

export default class LineChartDay extends Component {
  chartRef = React.createRef();
  myChart;

  componentDidMount() {
    if (this.props.values) this.buildChart();
  }

  componentDidUpdate() {
    if (this.props.values) this.updateChart();
  }

  updateChart = () => {
    const maxValue = Math.max(...this.props.values);
    const maxTicks = findMaxTicks(maxValue);

    const labels = this.props.labels;

    this.myChart.data.labels = labels;
    this.myChart.data.datasets[0].data = this.props.values;
    this.myChart.options.scales.yAxes[0].ticks.suggestedMax = maxTicks;

    this.myChart.update();
  }

  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext("2d");

    Chart.defaults.global.defaultFontFamily = "sans-serif";

    const maxValue = Math.max(...this.props.values);
    const maxTicks = findMaxTicks(maxValue);

    const labels = this.props.labels;

    this.myChart = new Chart(myChartRef, {
      type: "line", //bar, horizontalBar, pie, line, doughnut, radar, polarArea
      data: {
        labels,
        datasets: [
          {
            data: this.props.values,
            backgroundColor: "rgba(215, 240, 248, 1)",
            borderColor: "rgba(3, 179, 217, 1)",
            borderWidth: 2,
            label: this.props.dataLabelType
          }
        ]
      },
      options: {
        animation: false,
        aspectRatio: this.props.aspectRatio || 2,
        responsive: true,
        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMax: maxTicks,
                callback: value => this.props.dataLabelType + value
              }
            }
          ],
          xAxes: [
            {
              ticks: {
                callback: value => new Date(value).toLocaleDateString()
              }
            }
          ]
        },
        legend: {
          display: false
        },
        tooltips: { ...tooltips, callbacks: { title: value => {
          const epoch = value[0].label
          const dateObj = new Date(parseInt(epoch))
          return dateObj.toLocaleDateString();
        } } }  
      }
    });
  };

  onClick = ({ target }) => {
    console.log(target)
  }

  render() {
    return <canvas onClick={this.onClick} id={this.props.id} ref={this.chartRef} />;
  }
}
