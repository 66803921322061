import React, { Component } from 'react';

import Home from './pages/home';
import Sends from './pages/sends';
import Amp from './pages/amp';
import SendTemplate from './pages/sendTemplate';
import Yesterday from './pages/yesterday';
import Pub from './pages/pub';
import Promocode from './pages/promocode';
import Vender from './pages/vender';
import VenderRange from './pages/venderRange';
import PromocodesRange from './pages/promocodesRange';
import Nav from './components/Nav';
import LogIn from './components/LogIn';

import { 
  formatMoney, 
  formatOrderTable,
  getYesterdayISO 
} from './functions/helperFunctions';

export default class App extends Component {
  state = {
    ampType: 'month',
    data: [],
    detailedPub: null,
    detailedPCode: null,
    detailedVender: null,
    error: null,
    fetchURL: 'https://wiggum.agorafinancial.com/api/gawas/dashboard/home-dev/default',
    filteredData: [],
    headerText: '',
    historicalStartDate: '',
    historicalEndDate: '',
    intervalId: 0,
    isAmpFiltered: false,
    isNavOpen: false,
    isLoaded: false,
    isLoggedIn: false,
    page: 'home',
    pcodeData: [],
    pCodeACV: null,
    searchError: null,
    searchInputMain: '',
    searchInputVender: '',
    searchInputHistoricalPcode: '',
    searchInputHistoricalVender: '',
    searchType: 'today',
    sendType: 'created_after',
    searchTimeout: 0,
    sendStart: '',
    sendName: '',
    sendDate: getYesterdayISO(),
    sendValue: getYesterdayISO(),
    userType: null
  };

  getSendData() {
    if (!this.state.sendValue) {
      this.setState({
        isLoaded: true,
        data: [],
      });

      return;
    }

    const url = `https://api.paradigmpressgroup.com/api/bsreports?reportType=list_campaigns&${this.state.sendType}=${this.state.sendValue}`;

    fetch(url)
      .then(res => res.json())
      .then(result => {
        const filteredResults = result.filter(item => item.exec_term === 'one_time' && item.status !== 'draft');
        const subjectLines = filteredResults.map(item => item.subject_line?.trim());
        const fromLines = filteredResults.map(item => item.from_name?.trim());

        function find5MostFreq(array) {
          const sortedArray = array.toSorted();
          let freqObj = {};
          sortedArray.forEach((item, index) => {
            if (item === sortedArray[index + 1] && item) {
              freqObj[item] = freqObj[item] ? freqObj[item] + 1 : 2;
            }
          });
          const freqArr = Object.entries(freqObj).sort((a, b) => {
            if (a[1] > b[1]) {
              return -1
            } else {
              return 1
            }
          });
          if (freqArr.length > 1) freqArr.length = 1;
          return freqArr
        }

        const topSubjectLines = find5MostFreq(subjectLines);
        const topFromLines = find5MostFreq(fromLines);

        const data = {
          sends: filteredResults,
          topSubjectLines,
          topFromLines
        }

        this.setState({
          isLoaded: true,
          data,
        });
      },
      error => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  }

  getAmpData() {
    this.setState({ data: [], headerText: 'Sends <b class="text-green">Likes</b> & <b class="text-red">Dislikes</b>', isLoaded: false });

    const interval = this.state.ampType;
    const url = `https://api.paradigmpressgroup.com/api/ampstats?interval=${interval}`;

    fetch(url)
      .then(res => res.json())
      .then(result => {
        const data = Object.entries(result);
        this.setState({
          isLoaded: true,
          data,
          filteredData: data
        });
      },
      error => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  }

  getSendTemplate() {
    const params = new URLSearchParams(window.location.href);

    let headerText = params.size > 1 
      ? `Likes: ${params.getAll('likes')} | Dislikes: ${parseInt(params.getAll('dislikes'))}` 
      : '';

    fetch(this.state.fetchURL)
      .then(res => res.json())
      .then(result => {

        const fromName = result?.buckets[0].triggers[0].resource.from_name;
        const startDate = result?.startdate;
        const uuid = result.buckets[0].triggers[0].experiment_groups[0].experiments[0].template_uuid;
        const url = `https://api.paradigmpressgroup.com/api/bsreports?reportType=template_details&TEMPLATE_UUID=${uuid}`
        fetch(url)
          .then(res => res.json())
          .then(json => {
            const { resource, campaigns } = json;
            const { name } = campaigns[0];
            const { content, subject } = resource;
            const data = {
              content,
              subject,
              name,
              fromName,
              startDate
            }
            this.setState({
              isLoaded: true,
              headerText: headerText || name,
              data        
            });
          },
          error => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
      },
      error => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  }

  
  
  getData() {
    fetch(this.state.fetchURL)
      .then(res => res.json())
      .then(result => {
        if (result.totalPcode === null) {
          this.setState({ error: { message: 'No orders can be found' } })
          return;
        }

        // we don't need order count table for pcode page
        if (!this.state.detailedPCode) {
          var pcodeData = formatOrderTable(result);
        }

        // initialize header values
        let pCodeACV = null;
        let headerPreText = '';
        let headerPostText = '';
        let headerCount = pcodeData 
          ? pcodeData.reduce((acc, cur) =>  acc + cur.orderCount, 0) : 0;
        let headerTotal = '$0';

        // pub page
        if (this.state.detailedPub) {
          // if orderCount is 0 (ie no data)
          if (!headerCount) {
            this.setState({ error: { message: 'No main orders can be found for this pub' } })
          } else {
            headerPreText = `${this.state.detailedPub} | `
            // find batch total
            headerTotal = pcodeData
              ? formatMoney(pcodeData.reduce((acc, cur) => acc + cur.batch, 0)) : 0;
          }

          // promocode page
        } else if (this.state.detailedPCode) { 
          // find out if there is data for the promocode
          const isData = result.totalPcode !== null;
          // if no data
          if (!isData) {
            this.setState({ error: { message: 'No data can be found for this promocode' } })
            headerTotal = '$0';
          } else {
            headerPreText = `${this.state.detailedPCode} | `;

            // find header values
            const batch = result.totalPcode.reduce((acc, cur) => acc + parseInt(cur['SUM(total)']), 0);
            headerTotal = formatMoney(batch);
            headerCount = result.totalPcode.reduce((acc, cur) => acc + parseInt(cur['SUM(count)']), 0);
            pCodeACV = formatMoney(batch / headerCount);

            headerPostText = ` | ACV: ${pCodeACV}`;
          }

          // vender page
        } else if (this.state.detailedVender) { 
          pcodeData = pcodeData.filter(item => item.listname?.toUpperCase() === this.state.detailedVender.toUpperCase());

          const batch = pcodeData.reduce((acc, cur) => acc + parseInt(cur.batch), 0);
          // if no data
          if (!batch) {
            this.setState({ error: { message: 'No data can be found for this vendor' } })
            headerTotal = '$0';
            headerCount = '0';
          } else {
            headerPreText = `${this.state.detailedVender} | `;
            headerTotal = formatMoney(batch);
            headerCount = pcodeData.reduce((acc, cur) => acc + parseInt(cur.orderCount), 0);  
          }

          // if one of the history pages, don't live update
        } else if (
          this.state.page === 'vender_range' || 
          this.state.page === 'promocodes_range' ||
          this.state.page === 'yesterday' 
        ) {
          clearInterval(this.state.intervalId);
          headerTotal = formatMoney(result.batchTotal[0]['SUM(total)'])
        } else { 
          headerTotal = formatMoney(result.batchTotal[0]['SUM(total)'])
        }

        if (this.state.page === 'yesterday') {
          headerPreText = `Yesterday | `;
        }

        const headerText = `${headerPreText}Batch Total: ${headerTotal} | Orders: ${headerCount}${headerPostText}`

        this.setState({
          isLoaded: true,
          data: result,
          pcodeData,
          pCodeACV,
          headerText
        });
      },
      error => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    );
  }

  onAmpDateChange = ({ target }) => {
    this.setState({ ampType: target.value }, this.getAmpData);
  }

  onAmpFilterChange = ({ target }) => {
    const filter = target.textContent;
    const filteredData = this.state.data.filter(data => data[1].list_code === filter)

    const likes = filteredData.reduce((acc, cur) => acc + cur[1].likes, 0);
    const dislikes = filteredData.reduce((acc, cur) => acc + cur[1].dislikes, 0);

    const headerText = `${filter} | <b class="text-green">Likes: ${likes}</b> | <b class="text-red">Dislikes: ${dislikes}</b>`
  
    this.setState({ 
      filteredData, 
      headerText,
      isAmpFiltered: true
    });
  }

  onAmpFilterClear = () => {
    this.setState({ 
      filteredData: this.state.data,
      isAmpFiltered: false,
      headerText: 'Sends <b class="text-green">Likes</b> & <b class="text-red">Dislikes</b>'
    })
  }

  onSendDateChange = ({ target }) => {
    switch (target.name) {
      case 'sendType':
        this.setState({ 
          sendValue: '',
          sendDate: '',
          sendStart: '',
          sendName: '',
          sendType: target.value,
        }, this.getSendData);
        break;
      default:
        if (this.state.searchTimeout) clearTimeout(this.state.searchTimeout)
        const timeout = setTimeout(() => this.getSendData(), 300);
        this.setState({ searchTimeout: timeout })
      
        this.setState({ 
          [target.name]: target.value,
          sendValue: encodeURI(target.value.trim())
        });
        break;
    }
  }

  onSearchChange = ({ target }) => {
    this.setState({ [target.name]: target.value, searchError: null });
  }

  onSearchTypeChange = ({ target }) => {
    this.setState({ searchType: target.value })
  }

  submitSearch = e => {
    e.preventDefault();
    const searchMain = this.state.searchInputMain.toUpperCase();
    const searchVender = this.state.searchInputVender;
    const historicalVender = this.state.searchInputHistoricalVender;
    const historicalPcode = this.state.searchInputHistoricalPcode.replace(/\s/g, '');;
    const startDate = this.state.historicalStartDate;
    const endDate = this.state.historicalEndDate;

    if (this.state.searchType === 'historical') {
      if (historicalPcode && historicalVender) {
        this.setState({
          searchError: 'Only submit one input'
        });
      } else if (historicalVender) {
        window.location.href = `/vender_range/ppp?startdate=${startDate}&enddate=${endDate}&listname=${historicalVender}`;
      } else if (historicalPcode) {
        window.location.href = `/promocodes_range/ppp?startdate=${startDate}&enddate=${endDate}&promocodes=${historicalPcode}`;
      } else {
        this.setState({
          searchError: 'Please submit an input'
        });
      }
    }

    // throw error when there's values in both inputs
    else if (searchMain && searchVender) {
      this.setState({
        searchError: 'Only submit one input'
      });
    } else if (searchMain) {
      if (searchMain.length === 8) {
        window.location.href = '/' + searchMain;
      } else if (searchMain.length === 3) {
        window.location.href = '/' + searchMain;
      } else {
        this.setState({
          searchError: 'Search queries must be either 8 characters or 3 characters'
        });
      }
    } else if (searchVender) {
      window.location.href = '/Z' + searchVender;
    } 
  }

  logIn = () => {
    const userCookie = JSON.parse(localStorage.getItem('user'));
    const userType = userCookie.username;

    this.setState({ isLoggedIn: true, userType });
    this.route();

    let intervalId = setInterval(() => {
      const page = this.state.page
      console.log({ page })
      if (page === 'yesterday' || page.includes('send') || page === 'amp') {
        clearInterval(intervalId)
        return false;
      }
      this.getData();
    }, 60000);

    this.setState({ intervalId });

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        clearInterval(intervalId)
      } else {
        clearInterval(intervalId)
        intervalId = setInterval(() => {
          const page = this.state.page;
          console.log({ page });

          if (page === 'yesterday' || page.includes('send') || page === 'amp') {
            clearInterval(intervalId)
            return false;
          }  
          this.getData();
        }, 60000);
        this.setState({ intervalId });
      }
    });  
  }

  toggleNav = () => {
    this.setState(state => ({ isNavOpen: !state.isNavOpen }));
  }

  renderPage() {
    if (this.state.detailedPub) {
      return (
        <Pub
          error={this.state.error}
          detailedPub={this.state.detailedPub}
          data={this.state.data}
          pcodeData={this.state.pcodeData}
          isLoaded={this.state.isLoaded}
        />
      );
    } else if (this.state.detailedPCode) {
      return (
        <Promocode
          error={this.state.error}
          detailedPCode={this.state.detailedPCode}
          irisData={this.state.data.irisData && this.state.data.irisData[0]}
          pcodeData={this.state.data.totalPcode}
          isLoaded={this.state.isLoaded}
          acv={this.state.pCodeACV}
        />
      );
    } else if (this.state.detailedVender) {
      return (
        <Vender
          error={this.state.error}
          data={this.state.data}
          isLoaded={this.state.isLoaded}
          pcodeData={this.state.pcodeData}
          detailedVender={this.state.detailedVender}
        />
      )
    } else if (this.state.page === 'vender_range') {
      return (
        <VenderRange
          error={this.state.error}
          data={this.state.data}
          isLoaded={this.state.isLoaded}
        />
      )
    } else if (this.state.page === 'promocodes_range') {
      return (
        <PromocodesRange
          error={this.state.error}
          data={this.state.data}
          isLoaded={this.state.isLoaded}
          pcodeData={this.state.pcodeData}
          detailedPromocodes={this.state.detailedPromocodes}
        />
      )
    } else if (this.state.page === 'sends') {
      return (
        <Sends
          error={this.state.error}
          data={this.state.data}
          isLoaded={this.state.isLoaded}
          sendDate={this.state.sendDate}
          sendType={this.state.sendType}
          sendName={this.state.sendName}
          sendStart={this.state.sendStart}
          onSendDateChange={this.onSendDateChange}
        />
      )
    } else if (this.state.page === 'sendTemplate') {
      return (
        <SendTemplate
          error={this.state.error}
          data={this.state.data}
          isLoaded={this.state.isLoaded}
        />
      )
    } else if (this.state.page === 'amp') {
      return (
        <Amp
          ampType={this.state.ampType}
          error={this.state.error}
          data={this.state.filteredData}
          isLoaded={this.state.isLoaded}
          isFiltered={this.state.isAmpFiltered}
          onAmpFilterClear={this.onAmpFilterClear}
          onAmpDateChange={this.onAmpDateChange}
          onAmpFilterChange={this.onAmpFilterChange}
        />
      )
    } else if (this.state.page === 'yesterday') {
      return (
        <Yesterday
          error={this.state.error}
          data={this.state.data}
          isLoaded={this.state.isLoaded}
          pcodeData={this.state.pcodeData}
          userType={this.state.userType}
        />
      )
    } else {
      return (
        <Home
          error={this.state.error}
          data={this.state.data}
          isLoaded={this.state.isLoaded}
          pcodeData={this.state.pcodeData}
        />
      );
    }
  }

  route() {
    const urlRoute = window.location.href.split('/')[3];
    if (urlRoute.length === 3) {
      const pub = urlRoute.toUpperCase();
      this.setState({
        page: 'pub',
        isLoaded: false,
        fetchURL: `https://wiggum.agorafinancial.com/api/gawas/dashboard/pub/${pub}`,
        isNavOpen: false,
        detailedPub: pub,
      }, this.getData);
    } else if (urlRoute.length === 8 && urlRoute[0] !== 'Z') {
      const pCode = urlRoute.toUpperCase();
      this.setState({
        page: 'promocode',
        isLoaded: false,
        fetchURL: `https://wiggum.agorafinancial.com/api/gawas/dashboard/promocode/${pCode}`,
        isNavOpen: false,
        detailedPCode: pCode,
      }, this.getData);
    } else if (urlRoute[0] === 'Z') {
      const detailedVender = urlRoute.slice(1);
      this.setState({
        page: 'vender',
        isLoaded: false,
        fetchURL: 'https://wiggum.agorafinancial.com/api/gawas/dashboard/home-dev/default',
        detailedVender: decodeURIComponent(detailedVender),
      }, this.getData);
    } else if (urlRoute === 'vender_range') {
      const search = /vender_range\/.*/.exec(window.location.href)[0];
      this.setState({
        page: 'vender_range',
        isLoaded: false,
        fetchURL: 'https://wiggum.agorafinancial.com/api/gawas/dashboard/' + search,
      }, this.getData);
    } else if (urlRoute === 'promocodes_range') {
      const search = /promocodes_range\/.*/.exec(window.location.href)[0];
      const promocodes = /promocodes=(.*)/.exec(window.location.href)[1];
      const detailedPromocodes = promocodes.split(',');
      this.setState({
        page: 'promocodes_range',
        isLoaded: false,
        detailedPromocodes,
        fetchURL: 'https://wiggum.agorafinancial.com/api/gawas/dashboard/' + search,
      }, this.getData);
    } else if (urlRoute === 'yesterday') {
      this.setState({
        page: 'yesterday',
        isLoaded: false,
        fetchURL: 'https://wiggum.agorafinancial.com/api/gawas/dashboard/yesterday/cyf'
      }, this.getData);
    } else if (urlRoute === 'sends') {
      this.setState({
        page: 'sends',
        isLoaded: false      
      }, this.getSendData);
    } else if (urlRoute === 'sendTemplate') {
      const uuid = window.location.href.split('/')[4];

      this.setState({
        page: 'sendTemplate',
        fetchURL: `https://api.paradigmpressgroup.com/api/bsreports?reportType=campaign_details&CAMPAIGN_UUID=${uuid}`,
        isLoaded: false
      }, this.getSendTemplate);
    } else if (urlRoute === 'ampPage') {
      this.setState({
        page: 'amp',
        isLoaded: false
      }, this.getAmpData);
    } else {
      this.setState({
        page: 'home',
        isLoaded: false,
        fetchURL: 'https://wiggum.agorafinancial.com/api/gawas/dashboard/home-dev/default',
      }, this.getData);
    }
  }

  findUser = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    // if there is a cookie
    if (user) {
      const { username, password, expiry } = user;
    
      // if expired, clear cookie
      if (new Date().getTime() >= expiry) {
        localStorage.removeItem('user')
      } else {
        // make sure credentials are up to date
        if (username === 'admin' && password === '@dream_team!') {
          this.logIn();
          this.setState({ userType: 'admin' })
        } else if (username === 'paradigm' && password === '123letmein!') {
          this.logIn();
          this.setState({ userType: 'paradigm' })
        }
      }  
    }
  }

  componentDidMount() {
    this.findUser();
  }

  render() {
    const {
      headerText,
      historicalStartDate,
      historicalEndDate,
      isLoggedIn,
      isNavOpen,
      page,
      searchError,
      searchInputMain,
      searchInputVender,
      searchInputHistoricalVender,
      searchInputHistoricalPcode,
      searchType
    } = this.state;
    return (
      <>
        {!isLoggedIn && <LogIn logIn={this.logIn} />}
        <div id="app" className="grid ppp">
          <Nav
            historicalStartDate={historicalStartDate}
            historicalEndDate={historicalEndDate}
            isOpen={isNavOpen}
            onSearchTypeChange={this.onSearchTypeChange}
            onSearchChange={this.onSearchChange}
            page={page}
            searchError={searchError}
            searchInputMain={searchInputMain}
            searchInputVender={searchInputVender}
            searchInputHistoricalPcode={searchInputHistoricalPcode}
            searchInputHistoricalVender={searchInputHistoricalVender}
            searchType={searchType}
            submitSearch={this.submitSearch}
            userType={this.state.userType}
          />

          <main>
            <header>
              {this.state.page === 'sends' ? 
                <h1>
                  <img className="dispatchLogo" width="55" src="/dispatchadon70px.png" />
                  Dispatchadon
                </h1> :
                headerText ?
                <h1 id="headerAmount" dangerouslySetInnerHTML={{ __html: headerText }}></h1> :
                <h1 id="headerAmount">TRACKA</h1>
              }

              <button
                type="button"
                className={isNavOpen ? 'hamburger hamburger--open' : 'hamburger'}
                onClick={this.toggleNav}
              >
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
              </button>
            </header>
            <section className="mainContent active">
              {this.renderPage()}
            </section>
          </main>   
        </div>
      </>
    );
  }
}
