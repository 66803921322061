import React, { Component } from "react";

import LineChartDay from "../components/LineChartDay";
import OrdercountDay from "../components/ordercountDay";

const formatCountData = data => {
  const array = data.totalByDay || [];
  const results = array.map(datum => {
    return datum['SUM(count)']
  });
  return results;
}

const formatRevData = data => {
  const array = data.totalByDay || [];
  const results = array.map(datum => {
    return datum['SUM(total)']
  });
  return results;
}

const formatLabels = data => {
  const array = data.totalByDay || [];
  const results = array.map(datum => {
    let date = datum.day.split('-');
    let year = date[0];
    let month = date[1];
    let day = date[2];

    let epoch = new Date(year, month - 1, day).getTime();
    return epoch
  });
  return results;
}

const formatQueryDate = date => {
  const dateArr = date.split('-');
  const year = dateArr[0];
  const month = dateArr[1];
  const day = dateArr[2];

  return new Date(year, month - 1, day).toLocaleDateString();
}

class VenderRange extends Component {
  render() {
    const { error, isLoaded, data } = this.props;
    if (error) {
      return (
        <div className="mx-auto wrapper error">Error: {error.message}</div>
      );
    } else if (!isLoaded) {
      return (
        <div className="loading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    } else {
      const startDateRaw = /startdate=(\d{4}-\d{2}-\d{2})/.exec(window.location.href)[1];
      const endDateRaw = /enddate=(\d{4}-\d{2}-\d{2})/.exec(window.location.href)[1];

      const isOneDay = startDateRaw === endDateRaw;

      const startDate = formatQueryDate(startDateRaw);
      const endDate = formatQueryDate(endDateRaw);
      return (
        <div className="wrapper">
          <h1 className="pageHeading">
            {data.totalPcode[0].listname} <br />
            <span className="text-smaller">
              {isOneDay ? startDate : `${startDate} - ${endDate}`} 
            </span>
          </h1>
          {!isOneDay && 
            <div className="lg:flex">
              <div className="chartWrapper">
                <h2>Total Orders Per Day</h2>
                <LineChartDay
                  values={formatCountData(data)}
                  labels={formatLabels(data)}
                  dataLabelType=""
                  id=""
                />
              </div>
              <div className="chartWrapper">
                <h2>Total Revenue Per Day</h2>
                <LineChartDay
                  values={formatRevData(data)}
                  labels={formatLabels(data)}
                  dataLabelType="$"
                  id=""
                />
              </div>
            </div>
          }
         
          <h2 className="mb-0">Orders By Promocode</h2>
          <OrdercountDay startDate={startDateRaw} endDate={endDateRaw} info={data.totalPcode} />
        </div>
      )
    }
  }
}

export default VenderRange;
