import React, { Component } from "react";
import Chart from "chart.js";

import { randomColorArray, tooltips, findMaxTicks } from "../functions/chartHelpers";

export default class promocodeRevDaily extends Component {
  chartRef = React.createRef();
  myChart;

  componentDidMount() {
    if (this.props.info) this.buildChart();
  }

  componentDidUpdate() {
    if (this.props.info) this.updateChart();
  }

  formatData = dataSet => {
    // create chart data structure
    const promocodes = [...new Set(dataSet.map(datum => datum.promocode.toUpperCase()))];

    let chartData = promocodes.map((promocode, i) => {
      return {
        label: promocode,
        data: this.props.labels.map(label => {
          return { label, value: 0 }
        }),
        backgroundColor: randomColorArray[i],
        borderColor: randomColorArray[i],
      }
    });

    // iterate over raw data, populate chart data
    dataSet.forEach(datum => {
      const promocode = datum.promocode.toUpperCase();
      const promocodesIndex = chartData.findIndex(entry => entry.label === promocode);

      const date = datum.day.split('-');
      const year = date[0];
      const month = date[1];
      const day = date[2];

      const epoch = new Date(year, month - 1, day).getTime();

      const valuesIndex = chartData[promocodesIndex].data.findIndex(datum => datum.label === epoch)

      chartData[promocodesIndex].data[valuesIndex].value = datum['SUM(total)'];
    });

    chartData.forEach(promocode => {
      promocode.data = promocode.data.map(datum => parseInt(datum.value))
    })

    let dataValues = dataSet.map(datum => datum['SUM(total)']);

    const maxValue = Math.max(...dataValues);
    const maxTicks = findMaxTicks(maxValue);

    const labels = this.props.labels;

    return { chartData, maxTicks, labels };
  }

  updateChart = () => {
    const { chartData, maxTicks, labels } = this.formatData(this.props.info);

    this.myChart.data.labels = labels;
    this.myChart.data.datasets = chartData;
    this.myChart.options.scales.yAxes[0].ticks.max = maxTicks;

    this.myChart.update();
  }

  buildChart = () => {
    Chart.defaults.global.defaultFontFamily = "sans-serif";

    const myChartRef = this.chartRef.current.getContext("2d");

    let aspectRatio = 2;
    if (window.innerWidth > 830 && this.props.isImprint) {
      aspectRatio = 4
    }

    const { chartData, maxTicks, labels } = this.formatData(this.props.info);

    this.myChart = new Chart(myChartRef, {
      type: "line",
      data: {
        labels: labels,
        datasets: chartData,
      },
      options: {
        animation: false,
        responsive: true,
        aspectRatio,
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: false,
              },
              ticks: {
                max: maxTicks,
                callback: function(value) {
                  return "$" + value;
                },
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                callback: value => new Date(value).toLocaleDateString()
              }
            }
          ],
        },
        legend: {
          display: true,
          position: "right",
          labels: {
            boxWidth: 15,
          },
        },
        tooltips,
      },
    });
  };
  render() {
    return <canvas id="pubRevHour" ref={this.chartRef} />;
  }
}
