import React, { Component } from "react";

import Ordercount from "../components/ordercount";
import LineChart from "../components/LineChart";

const formatCountHourlyData = (data, pcodes) => {
  const values = new Array(24).fill(0);

  data.forEach(datum => {
    if (pcodes.indexOf(datum.promocode) !== -1) {
      let hour = parseInt(datum.hr);
      hour = (hour < 6) ? hour + 18 : hour - 6;

      values[hour] +=  parseInt(datum['SUM(count)']);
    }
  });

  return values;
}
const formatRevHourlyData = (data, pcodes) => {
  const values = new Array(24).fill(0);

  data.forEach(datum => {
    if (pcodes.indexOf(datum.promocode) !== -1) {
      let hour = parseInt(datum.hr);
      hour = (hour < 6) ? hour + 18 : hour - 6;

      values[hour] +=  parseInt(datum['SUM(total)']);
    }
  });

  return values;
}
const formatACVHourlyData = (data, pcodes) => {
  const values = new Array(24).fill(0);

  data.forEach(datum => {
    if (pcodes.indexOf(datum.promocode) !== -1) {
      let hour = parseInt(datum.hr);
      hour = (hour < 6) ? hour + 18 : hour - 6;

      if (values[hour] === 0) {
        values[hour] = {};
        values[hour].orders = parseInt(datum['SUM(count)']);
        values[hour].batch = parseInt(datum['SUM(total)']);
      } else {
        values[hour].orders += parseInt(datum['SUM(count)']);
        values[hour].batch += parseInt(datum['SUM(total)']);
      }
    }
  });

  return values.map(value => {
    if (value !== 0) {
      return value.batch / value.orders
    } else {
      return 0
    }
  });
}

class Vender extends Component {
  render() {
    const { error, data, isLoaded, pcodeData } = this.props;
    if (error) {
      return (
        <div className="mx-auto wrapper error">Error: {error.message}</div>
      );
    } else if (!isLoaded) {
      return (
        <div className="loading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    } else {
      const pcodes = pcodeData.map(item => item.promocode);

      return (
        <div className="wrapper home">
          <div className="lg:flex">
          <div className="chartWrapper">
            <h2>Daily Batch Revenue By Hour</h2>
            <LineChart
              values={formatRevHourlyData(data.pcodeByHour, pcodes)}
              dataLabelType="$"
              id="pubRev"
            />
          </div>         
          <div className="chartWrapper">
            <h2>ACV By Hour</h2>
            <LineChart
              values={formatACVHourlyData(data.pcodeByHour, pcodes)}
              dataLabelType="$"
              id=""
            />
          </div>
          </div>
          <div className="chartWrapper">
            <h2>Order Count By Hour</h2>
            <LineChart
              values={formatCountHourlyData(data.pcodeByHour, pcodes)}
              aspectRatio="4"
              dataLabelType=""
              id=""
            />
          </div>
          <h2 className="mb-0">Orders By Promocode</h2>
          <Ordercount info={pcodeData} isVender="true" />
        </div>
      );
    }
  }
}
export default Vender;
