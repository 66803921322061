import React from 'react';

import LineChart from '../components/LineChart';
import Ordercount from '../components/ordercount';

import { formatRevHourlyData, formatCountHourlyData } from '../functions/helperFunctions';

export default function Pub({ error, isLoaded, data, detailedPub, pcodeData }) {
  if (error) {
    return <div className="mx-auto wrapper error"><h2>Error: {error.message}</h2></div>;
  } else if (!isLoaded) {
    return (
      <div className="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  } else {
    return (
      <div className="wrapper pub">
        <div className="lg:flex">
          <div className="chartWrapper">
            <h2>{detailedPub} Rev By Hour</h2>
            <LineChart
              values={formatRevHourlyData(data.totalPcode)}
              dataLabelType="$"
              id=""
            />
          </div>
          <div className="chartWrapper">
            <h2>{detailedPub} Orders By Hour</h2>
            <LineChart
              values={formatCountHourlyData(data.totalPcode)}
              dataLabelType=""
              id=""
            />
          </div>
        </div>
        <h2 className="mb-0">{detailedPub} Orders By Promocode</h2>
        <Ordercount
          isPub={true}
          info={pcodeData}
        />
      </div>
    )
  }
}