import React, { Component } from "react";

class Amp extends Component {
  state = {
    sortType: 'sendTime',
    sortDirection: 'descending',
  };

  setSort = ({ target }) => {
    const sortType = target.getAttribute('data-type');
    const sortDirection = target.getAttribute('data-direction');
    this.setState({ sortType, sortDirection });
  };

  filterByList = ({ target }) => {
    const filter = target.textContent;
    const filteredData = this.props.data.filter(data => data[1].list_code === filter)
    
    this.setState({ filteredData, filter });
  };

  findLikes = (array) => array.reduce((acc, cur) => acc + cur[1].likes, 0);
  findDislikes = (array) => array.reduce((acc, cur) => acc + cur[1].dislikes, 0);

  sortFunction = (a, b) => {
    const sortDirection = this.state.sortDirection;
    const sortType = this.state.sortType;

    let val1;
    let val2;

    if (sortType === 'sendTime') {
      val1 = new Date(a[1].send_date).getTime();
      val2 = new Date(b[1].send_date).getTime();
    } else if (sortType === 'likes') {
      val1 = a[1].likes;
      val2 = b[1].likes;
    } else if (sortType === 'dislikes') {
      val1 = a[1].dislikes;
      val2 = b[1].dislikes;
    }

    if (val1 > val2) {
      return sortDirection === 'ascending' ? 1 : -1;
    } else {
      return sortDirection === 'ascending' ? -1 : 1;
    }
  };

  render() {
    const {
      ampType,
      data,
      error,
      isLoaded,
      isFiltered,
      onAmpDateChange,
      onAmpFilterChange,
      onAmpFilterClear
    } = this.props;

    const sortedData = this.state.filteredData ? [...this.state.filteredData] : [...data];
    sortedData.sort(this.sortFunction);

    const { sortType, sortDirection } = this.state;

    const dateOptions = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    if (error) {
      return (
        <div className="mx-auto wrapper error">Error: {error.message}</div>
      );
    } else if (!isLoaded) {
      return (
        <div className="loading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    } else {
      return (
        <div className="wrapper home">
            <nav className="flex items-center sendSearch">
              <span role="img" aria-label="search icon" className="hidden lg:block sendImg">🔍</span>
              <label htmlFor="ampDate">All results from this</label>
              <select id="ampDate" value={ampType} onChange={onAmpDateChange}>
                <option value="year">year</option>
                <option value="month">month</option>
                <option value="week">week</option>
                {/* <option value="day">day</option> */}
              </select>
            </nav>

            {isFiltered && 
              <button className="ampClearFilter" onClick={onAmpFilterClear}>
                Clear Filter
              </button>
            }
          <div className="tableWrapper">
            <table id="promocodeList">
              <thead>
                <tr>
                  <th>
                    Send Time
                    <div className="buttonWrapper">
                      <button
                        disabled={
                          sortType === "sendTime" &&
                          sortDirection === "ascending"
                        }
                        className="upArrow"
                        data-type="sendTime"
                        data-direction="ascending"
                        onClick={this.setSort}
                      ></button>
                      <button
                        disabled={
                          sortType === "sendTime" &&
                          sortDirection === "descending"
                        }
                        className="downArrow"
                        data-type="sendTime"
                        data-direction="descending"
                        onClick={this.setSort}
                      ></button>
                    </div>
                  </th>
                  <th>List Code</th>
                  <th>Subject Line</th>
                  <th>From Name</th>
                  <th>Likes
                  <div className="buttonWrapper">
                      <button
                        disabled={
                          sortType === "likes" &&
                          sortDirection === "ascending"
                        }
                        className="upArrow"
                        data-type="likes"
                        data-direction="ascending"
                        onClick={this.setSort}
                      ></button>
                      <button
                        disabled={
                          sortType === "likes" &&
                          sortDirection === "descending"
                        }
                        className="downArrow"
                        data-type="likes"
                        data-direction="descending"
                        onClick={this.setSort}
                      ></button>
                    </div>
                  </th>
                  <th>Dislikes
                  <div className="buttonWrapper">
                      <button
                        disabled={
                          sortType === "dislikes" &&
                          sortDirection === "ascending"
                        }
                        className="upArrow"
                        data-type="dislikes"
                        data-direction="ascending"
                        onClick={this.setSort}
                      ></button>
                      <button
                        disabled={
                          sortType === "dislikes" &&
                          sortDirection === "descending"
                        }
                        className="downArrow"
                        data-type="dislikes"
                        data-direction="descending"
                        onClick={this.setSort}
                      ></button>
                    </div>
                  </th>
                  <th>Unique Clicks</th>
                  <th>Deliveries</th>
                </tr>
              </thead>
              <tbody>
                {sortedData &&
                  sortedData.map((item) => (
                    <tr key={item[0]}>
                      <td>
                        <a href={`/sendTemplate/${item[0]}/&likes=${item[1].likes}&dislikes=${item[1].dislikes}`}>
                          {new Date(item[1].send_date).toLocaleString(
                            'en-US',
                            dateOptions
                          )}
                        </a>
                      </td>
                      {
                        isFiltered 
                        ? <td>{item[1].list_code}</td>
                        : <td><button onClick={onAmpFilterChange} type="button" className="ampSetFilter">{item[1].list_code}</button></td>
                      }
                      <td>{item[1].subject_line}</td>
                      <td>{item[1].from_name}</td>
                      <td className="text-green"><b>{item[1].likes}</b></td>
                      <td className="text-red"><b>{item[1].dislikes}</b></td>
                      <td>{item[1].unique_clicks}</td>
                      <td>{item[1].deliveries}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
}
export default Amp;
