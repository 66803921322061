import React, { Component } from 'react';

export default class ordercount extends Component {
  state = {
    sortType: 'batch',
    sortDirection: 'descending'
  }
  
  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  });

  findYesterday = () => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    const year = date.getFullYear();
    let month = date.getMonth() + 1 + '';
    month = month.padStart(2, '0');
    let day = date.getDate() + '';
    day = day.padStart(2, '0');
    return `${year}-${month}-${day}`
  }

  createListnameLink = listname => {
    if (this.props.page === 'yesterday') {
      const yesterday = this.findYesterday();
      return `/vender_range/ppp?startdate=${yesterday}&enddate=${yesterday}&listname=${listname}`
    } else {
      return `/Z${encodeURIComponent(listname)}`
    }
  }

  createPcodeLink = pcode => {
    if (this.props.page === 'yesterday') {
      const yesterday = this.findYesterday();
      return `/promocodes_range/ppp?startdate=${yesterday}&enddate=${yesterday}&promocodes=${pcode}`
    } else {
      return `/${pcode}`
    }
  }

  setSort = ({ target }) => {
    const sortType = target.getAttribute('data-type');
    const sortDirection = target.getAttribute('data-direction');
    this.setState({ sortType, sortDirection });
  }

  sortFunction = (a, b) => {
    const sortKey = this.state.sortType;
    const sortDirection = this.state.sortDirection;

    if (sortKey === 'baseURL') {
      const x = this.readableURL(a.baseURL);
      const y = this.readableURL(b.baseURL);
      if (x < y) {
        return sortDirection === 'ascending' ? -1 : 1;
      } else if (x > y) {
        return sortDirection === 'ascending' ? 1 : -1;
      }
    }

    if (a[sortKey] < b[sortKey]) {
      return sortDirection === 'ascending' ? -1 : 1;
    } else if (a[sortKey] > b[sortKey]) {
      return sortDirection === 'ascending' ? 1 : -1;
    }
  };

  readableURL = url => {
    const urlArray = url.split('/');
    if (urlArray[3] === 'journey') {
      return urlArray[4]
    } else {
      return urlArray[3]
    }
  }

  render() {
    const sortedData = [...this.props.info];
    sortedData.sort(this.sortFunction);

    const { sortType, sortDirection } = this.state;

    return (
      <div className="tableWrapper">
        <table id="promocodeList">
          <thead>
            <tr>
              <th>
                <div className="rotated"><span>Promocode</span></div>
                <div className="buttonWrapper">
                  <button
                    disabled={sortType === 'promocode' && sortDirection === 'ascending'}
                    className="upArrow"
                    data-type="promocode"
                    data-direction="ascending"
                    onClick={this.setSort}>
                  </button>
                  <button
                    disabled={sortType === 'promocode' && sortDirection === 'descending'}
                    className="downArrow"
                    data-type="promocode"
                    data-direction="descending"
                    onClick={this.setSort}>
                  </button>
                </div>
              </th>
              <th>
                <div className="rotated"><span>Orders</span></div>
                <div className="buttonWrapper">
                  <button
                    disabled={sortType === 'orderCount' && sortDirection === 'ascending'}
                    className="upArrow"
                    data-type="orderCount"
                    data-direction="ascending"
                    onClick={this.setSort}>
                  </button>
                  <button
                    disabled={sortType === 'orderCount' && sortDirection === 'descending'}
                    className="downArrow"
                    data-type="orderCount"
                    data-direction="descending"
                    onClick={this.setSort}>
                  </button>
                </div>
              </th>
              <th>
                <div className="rotated"><span>Batch</span></div>
                <div className="buttonWrapper">
                  <button
                    disabled={sortType === 'batch' && sortDirection === 'ascending'}
                    className="upArrow"
                    data-type="batch"
                    data-direction="ascending"
                    onClick={this.setSort}>
                  </button>
                  <button
                    disabled={sortType === 'batch' && sortDirection === 'descending'}
                    className="downArrow"
                    data-type="batch"
                    data-direction="descending"
                    onClick={this.setSort}>
                  </button>
                </div>
              </th>
              <th className="hidden lg:table-cell">
                <div className="rotated"><span>Upsells</span></div>
                <div className="buttonWrapper">
                  <button
                    disabled={sortType === 'upsellCount' && sortDirection === 'ascending'}
                    className="upArrow"
                    data-type="upsellCount"
                    data-direction="ascending"
                    onClick={this.setSort}>
                  </button>
                  <button
                    disabled={sortType === 'upsellCount' && sortDirection === 'descending'}
                    className="downArrow"
                    data-type="upsellCount"
                    data-direction="descending"
                    onClick={this.setSort}>
                  </button>
                </div>
              </th>
              {!this.props.isPub &&
                <th className="hidden lg:table-cell">
                  <div className="rotated"><span>Cross Sells</span></div>
                  <div className="buttonWrapper">
                    <button
                      disabled={sortType === 'crossSellCount' && sortDirection === 'ascending'}
                      className="upArrow"
                      data-type="crossSellCount"
                      data-direction="ascending"
                      onClick={this.setSort}>
                    </button>
                    <button
                      disabled={sortType === 'crossSellCount' && sortDirection === 'descending'}
                      className="downArrow"
                      data-type="crossSellCount"
                      data-direction="descending"
                      onClick={this.setSort}>
                    </button>
                  </div>
                </th>
              }
              <th>
                <div className="rotated"><span>ACV</span></div>
                <div className="buttonWrapper">
                  <button
                    disabled={sortType === 'acv' && sortDirection === 'ascending'}
                    className="upArrow"
                    data-type="acv"
                    data-direction="ascending"
                    onClick={this.setSort}>
                  </button>
                  <button
                    disabled={sortType === 'acv' && sortDirection === 'descending'}
                    className="downArrow"
                    data-type="acv"
                    data-direction="descending"
                    onClick={this.setSort}>
                  </button>
                </div>
              </th>            
              {!this.props.isVender &&
              <th>
                <div className="md:rotated"><span>Vendor</span></div>
                <div className="buttonWrapper">
                  <button
                    disabled={sortType === 'listname' && sortDirection === 'ascending'}
                    className="upArrow"
                    data-type="listname"
                    data-direction="ascending"
                    onClick={this.setSort}>
                  </button>
                  <button
                    disabled={sortType === 'listname' && sortDirection === 'descending'}
                    className="downArrow"
                    data-type="listname"
                    data-direction="descending"
                    onClick={this.setSort}>
                  </button>
                </div>
              </th>
              } 
              <th className="hidden lg:table-cell">
                <div className="notRotated">Link to OF</div>
                <div className="buttonWrapper">
                  <button
                    disabled={sortType === 'baseURL' && sortDirection === 'ascending'}
                    className="upArrow"
                    data-type="baseURL"
                    data-direction="ascending"
                    onClick={this.setSort}>
                  </button>
                  <button
                    disabled={sortType === 'baseURL' && sortDirection === 'descending'}
                    className="downArrow"
                    data-type="baseURL"
                    data-direction="descending"
                    onClick={this.setSort}>
                  </button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData && sortedData.map(item => (
                <tr key={item.promocode}>
                  <td>
                    <a className="pcodeLink" href={this.createPcodeLink(item.promocode)}>
                      {item.promocode}
                    </a>
                  </td>
                  <td>{item.orderCount}</td>
                  <td>{this.formatter.format(item.batch)}</td>
                  <td className="hidden lg:table-cell">{item.upsellCount}</td>
                  {!this.props.isPub && <td className="hidden lg:table-cell">{item.crossSellCount}</td>}
                  <td>{this.formatter.format(item.acv)}</td>
                  {!this.props.isVender &&
                  <td>
                    {item.listname !== 'Error: Not Found' &&
                      <a className="pcodeLink" href={this.createListnameLink(item.listname)}>
                      {item.listname}
                      </a>
                    }
                    {item.listname === 'Error: Not Found' &&
                      item.listname
                    }
                  </td>
                  }
                  <td className="hidden lg:table-cell"><a target="_blank" rel="noopener noreferrer" href={item.baseURL}>{this.readableURL(item.baseURL)}</a></td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    );
  }
}
