export const formatMoney = num => {
  const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    });
    return formatter.format(num)
}

export const formatRevHourlyData = data => {
  const values = new Array(24).fill(0);
  data.forEach(datum => {
    let hour = parseInt(datum.hr);
    hour = (hour < 6) ? hour + 18 : hour - 6;

    values[hour] +=  parseInt(datum['SUM(total)']);
  });
  return values;
}

export const formatCountHourlyData = data => {
  const values = new Array(24).fill(0);
  data.forEach(datum => {
    let hour = parseInt(datum.hr);
    hour = (hour < 6) ? hour + 18 : hour - 6;

    values[hour] +=  parseInt(datum['SUM(count)']);
  });
  return values;
}

export const formatACVHourlyData = data => {
  const values = new Array(24).fill(0);

  data.forEach(datum => {
    let hour = parseInt(datum.hr);
    hour = (hour < 6) ? hour + 18 : hour - 6;

    if (values[hour] === 0) {
      values[hour] = {};
      values[hour].orders = parseInt(datum['SUM(count)']);
      values[hour].batch = parseInt(datum['SUM(total)']);
    } else {
      values[hour].orders += parseInt(datum['SUM(count)']);
      values[hour].batch += parseInt(datum['SUM(total)']);
    }
  });

  return values.map(value => {
    if (value !== 0) {
      return value.batch / value.orders
    } else {
      return 0
    }
  });
}

export const formatURL = url => {
  const fullURL = decodeURIComponent(url);
  const baseURL = fullURL.slice(0, fullURL.indexOf('?'));
  return baseURL;
}

export const formatOrderTable = ({ totalPcode }) => {
  if (!totalPcode) return null

  const dataStructure = {};
  totalPcode.forEach(entry => {
    const promocode = entry.promocode.trim();
    const orderType = entry.comment ? entry.comment.toUpperCase() : '';
    const batch = parseInt(entry['SUM(total)']);
    const orderCount = parseInt(entry['SUM(count)']);

    // if this promocode has already been logged
    if (dataStructure[promocode]) {
      // bug fix where listname is sometimes u
      if (dataStructure[promocode].listname === 'u') {
        dataStructure[promocode].listname = entry.listname
      }
      // add new values to old
      dataStructure[promocode].orderCount += orderCount;
      dataStructure[promocode].batch += batch;
      if (orderType === 'UPSELL') {
        dataStructure[promocode].upsellCount += 1;
      } else if (orderType === 'CROSS SELL' || orderType === 'CROSSSELL') {
        dataStructure[promocode].crossSellCount += 1;
      }
    } else {
      if (entry.listname === 'u') {
        entry.listname = 'Error: Not Found'
      }
      dataStructure[promocode] = {
        promocode,
        orderCount,
        batch,
        pub: entry.pub.toUpperCase(),
        upsellCount: orderType === 'UPSELL' ? 1 : 0,
        crossSellCount: orderType === 'CROSS SELL' ||
          orderType === 'CROSSSELL' ? 1 : 0,
        listname: entry.listname ? entry.listname : null, 
        baseURL: formatURL(entry.url),
      }
    }
    
  });
  // return data structure while adding the ACV for each pcode
  return Object.values(dataStructure).map(entry => {
    entry.acv = entry.batch / entry.orderCount;
    return entry;
  });
}

export const getYesterdayISO = () => {
  const dateObj = new Date();
  const date = dateObj.getDate();
  dateObj.setDate(date - 1);
  return dateObj.toISOString().split('T')[0]
}