import React, { Component } from 'react';

export default class LogIn extends Component {
  state = {
    username: '',
    password: ''
  }

  onChange = ({ target }) => {
    this.setState({ 
      [target.name]: target.value,
      error: '' 
    })
  }

  onSubmit = e => {
    e.preventDefault();
    let { username, password } = this.state;
    username = username.toLowerCase();
    password = password.toLowerCase();
    if (username === 'admin' && password === '@dream_team!') {
      this.storeLogIn({ username, password });
      this.props.logIn();
    } else if (username === 'paradigm' && password === '123letmein!') {
      this.storeLogIn({ username, password });
      this.props.logIn();
    } else {
      this.setState({ error: 'Please Try Again'})
    }
  }

  storeLogIn = ({ username, password }) => {
    const payload = {
      username,
      password,
      expiry: new Date().getTime() + 31557600000
    }
    localStorage.setItem('user', JSON.stringify(payload))
  }

  render() {
    return (
      <div className="log_in_form">
      <form onSubmit={this.onSubmit} className={this.state.error && 'shake'}>
        <h2 className="text-center">Please Log In</h2>
        <label className="mx-auto">
          Username
          <input
            value={this.state.username}
            name="username"
            type="text" 
            onChange={this.onChange}
            autoCorrect="off" 
            autoCapitalize="none"
          />
        </label>
        <label className="mt-4 mx-auto">
          Password
          <input
            value={this.state.password}
            name="password"
            type="password" 
            onChange={this.onChange}
          />
        </label>
        {this.state.error && <p className="text-center text-red bold">{this.state.error}</p>}
        <button>SUBMIT</button>
      </form>
      </div>
    )
  }
}