import React, { Component } from "react";

class SendTemplate extends Component {
  render() {
    const { error, data, isLoaded } = this.props;

    const {
      content,
      subject,
      fromName,
      name,
      startDate
     } = data;

     const dateOptions = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    if (error) {
      return (
        <div className="mx-auto wrapper error">Error: {error.message}</div>
      );
    } else if (!isLoaded) {
      return (
        <div className="loading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    } else {
      return (
        <div className="wrapper home">
          <div className="sendTemplateWrapper">
            <div className="mx-auto sendTemplateGrid">
              <p><b>Name:</b> {name}</p>
              <p><b>Subject:</b> {subject}</p>
              <p><b>From Name:</b> {fromName}</p>         
              <p><b>Send Time:</b> {
                new Date(startDate).toLocaleString('en-US', dateOptions)
              }</p>
            </div>
          </div>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      );
    }
  }
}
export default SendTemplate;
