import React, { Component } from "react";

import LineChartDay from "../components/LineChartDay";
import OrdercountDay from "../components/ordercountDay";
import PromocodeRevDaily from "../components/promocodeRevDaily";


const dateToEpoch = dateStr => {
  const [year, month, day] = dateStr.split('-');
  const epoch = new Date(year, month - 1, day).getTime();
  return epoch
}

const formatData = ({ totalPcode }) => {
  const dataStructure = {};
  totalPcode.forEach(datum => {
    const label = dateToEpoch(datum.day)
    if (dataStructure[label]) {
      dataStructure[label].count += parseInt(datum['SUM(count)']);
      dataStructure[label].rev += parseInt(datum['SUM(total)']);

    } else {
      dataStructure[label] = {
        label, 
        count: parseInt(datum['SUM(count)']), 
        rev: parseInt(datum['SUM(total)'])
      }
    }
  })
  return Object.values(dataStructure).sort((a, b) => {
    if (a.label > b.label) {
      return 1
    } else {
      return -1
    }
  })
}

const formatCountData = data => {
  const array = formatData(data);
  const results = array.map(datum => datum.count);
  return results;
}

const formatRevData = data => {
  const array = formatData(data);
  const results = array.map(datum => datum.rev);
  return results;
}

const formatLabels = data => {
  const array = data.totalByDay || [];
  const results = array.map(datum => {
    return dateToEpoch(datum.day)
  });
  return [...new Set(results.sort())];
}

const formatQueryDate = date => {
  return new Date(dateToEpoch(date)).toLocaleDateString();
}

class PromocodesRange extends Component {
  render() {
    const { error, isLoaded, data, detailedPromocodes } = this.props;
    if (error) {
      return (
        <div className="mx-auto wrapper error">Error: {error.message}</div>
      );
    } else if (!isLoaded) {
      return (
        <div className="loading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    } else {
      const startDateRaw = /startdate=(\d{4}-\d{2}-\d{2})/.exec(window.location.href)[1];
      const endDateRaw = /enddate=(\d{4}-\d{2}-\d{2})/.exec(window.location.href)[1];

      const isOneDay = startDateRaw === endDateRaw;

      const startDate = formatQueryDate(startDateRaw);
      const endDate = formatQueryDate(endDateRaw);
      return (
        <div className="wrapper">
          <h1 className="pageHeading">
            {detailedPromocodes.join(', ')} <br />
            <span className="text-smaller">
              {isOneDay ? startDate : `${startDate} - ${endDate}`} 
            </span>          
          </h1>
          {!isOneDay && 
            <div className="lg:flex">
              <div className="chartWrapper">
                <h2>Total Orders Per Day</h2>
                <LineChartDay
                  values={formatCountData(data)}
                  labels={formatLabels(data)}
                  dataLabelType=""
                  id=""
                />
              </div>
              <div className="chartWrapper">
                <h2>Total Revenue Per Day</h2>
                <LineChartDay
                  values={formatRevData(data)}
                  labels={formatLabels(data)}
                  dataLabelType="$"
                  id=""
                />
              </div>
            </div>
          }
          {
            (detailedPromocodes.length > 1) && 
            <div className="chartWrapper hidden lg:block">
            <h2>Daily Batch Revenue By Promocode</h2>
            <PromocodeRevDaily labels={formatLabels(data)} isImprint={true} info={data.totalPcode} />
          </div>
          }
         
          <h2 className="mb-0">Orders By Promocode</h2>
          <OrdercountDay  startDate={startDateRaw} endDate={endDateRaw} info={data.totalPcode} />
        </div>
      )
    }
  }
}

export default PromocodesRange;
