import React, { Component } from 'react';

export default class Nav extends Component {
  state = {
    isSearchOpen: false,
    isHistorialSearch: false,
    historySearchPage: 'date'
  }
  changeSearchDisplay = () => {
    this.setState(prevState => { 
      return { isSearchOpen: !prevState.isSearchOpen }
    })
  }

  getClassName = (page, link) => {
    switch (page) {
      case 'sendTemplate':
        return 'emojiLink-emoji'
      case 'sends':
        return page === link ? 'emojiLink-emoji active' : 'emojiLink-emoji'
      case 'amp':
        return page === link ? 'emojiLink-emoji active' : 'emojiLink-emoji'
      default: 
        if (link.includes('send') || link === 'amp') {
          return 'emojiLink-emoji'
        } else {
          return 'emojiLink-emoji active'
        }
    }
  }

  render() {
    const {
      historicalStartDate,
      historicalEndDate,
      isOpen,
      onSearchChange,
      onSearchTypeChange,
      page,
      searchError,
      searchInputMain,
      searchInputHistoricalPcode,
      searchInputHistoricalVender,
      searchInputVender,
      searchType,
      submitSearch,
      userType
    } = this.props;

    let pagetype;

    if (page === 'pub' || page === 'promocode') {
      pagetype = page;
    }

    let historySearchView;

    if (this.state.historySearchPage === 'input') {
      historySearchView = (
        <div className="historicalInputWrapper">
          <h2>Choose One Input:</h2>
        <label>
          <h3>Promocodes <span className="text-smaller block">(separate by comma)</span></h3>
          <input 
            type="text"
            className="searchInput"
            name="searchInputHistoricalPcode"
            value={searchInputHistoricalPcode}
            onChange={onSearchChange}
          />
        </label>
        <p className="text-center bold">- or -</p>

        <label>
          <h3>Vendor</h3>
          <input 
            type="text"
            className="searchInput"
            name="searchInputHistoricalVender"
            value={searchInputHistoricalVender}
            onChange={onSearchChange}
          />
        </label>
        <button onClick={() => this.setState({ historySearchPage: 'date' })}>Previous</button>
        <button className="searchSubmitBtn">Submit</button>
      </div>
      
      )
    } else if (this.state.historySearchPage === 'date') {
      historySearchView = (
       
          <div className="historicalDateWrapper">
            <h2>Date Range</h2>
            <label>
              <h3>Start Date</h3>
              <input 
                type="date"
                className="searchInput"
                name="historicalStartDate"
                value={historicalStartDate}
                onChange={onSearchChange}
                min="2022-01-01"
              />
            </label>
            <label>
              <h3>End Date</h3>
              <input 
                type="date"
                className="searchInput"
                name="historicalEndDate"
                value={historicalEndDate}
                onChange={onSearchChange}
                min={historicalStartDate}
              />
            </label>
            <button
              disabled={!historicalEndDate || !historicalStartDate} 
              onClick={() => this.setState({ historySearchPage: 'input' })}
            >Next</button>

          </div>
      )
    }

    return (
      <nav className={isOpen ? `sidebar sidebar--open ${pagetype}` : `sidebar ${pagetype}`}>
        <img className="imprint-logo" width="100" src="https://d1u56hvpilpmef.cloudfront.net/Paradigm/Paradigm_Logomark_White.png" alt="Home" />
        <a href="/" className="emojiLink">
          <span role="img" aria-labelledby="ordersLink" className={this.getClassName(page, '')}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="white" height="30" viewBox="0 -960 960 960" width="30"><path d="M441-120v-86q-53-12-91.5-46T293-348l74-30q15 48 44.5 73t77.5 25q41 0 69.5-18.5T587-356q0-35-22-55.5T463-458q-86-27-118-64.5T313-614q0-65 42-101t86-41v-84h80v84q50 8 82.5 36.5T651-650l-74 32q-12-32-34-48t-60-16q-44 0-67 19.5T393-614q0 33 30 52t104 40q69 20 104.5 63.5T667-358q0 71-42 108t-104 46v84h-80Z"/></svg>
          </span>
          <span style={{ fontSize: '0.75rem'}} id="ordersLink" className="emojiLink-text">ORDERS</span>
        </a>
       
        <a href="/sends" className="emojiLink">
          <span role="img" aria-labelledby="sendsLink" className={this.getClassName(page, 'sends')}>
            <svg width="30" height="30" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z"/></svg>
          </span>
          <span id="sendsLink" className="emojiLink-text">SENDS</span>
        </a>

        <a href="/ampPage" className="emojiLink">
          <span role="img" aria-labelledby="ampLink" className={this.getClassName(page, 'amp')}>
            <svg xmlns="http://www.w3.org/2000/svg" idth="30" height="30" fill="white" viewBox="0 -960 960 960"><path d="M720-120H280v-520l280-280 50 50q7 7 11.5 19t4.5 23v14l-44 174h258q32 0 56 24t24 56v80q0 7-2 15t-4 15L794-168q-9 20-30 34t-44 14Zm-360-80h360l120-280v-80H480l54-220-174 174v406Zm0-406v406-406Zm-80-34v80H160v360h120v80H80v-520h200Z"/></svg>
          </span>
          <span id="sendsLink" className="emojiLink-text">AMP</span>
        </a>
       
        { !page.includes('send') && !page.includes('amp') &&
          <>
        <hr></hr>

        <input onChange={this.changeSearchDisplay} checked={this.state.isSearchOpen} type="checkbox" id="searchCheckbox" />
        <label htmlFor="searchCheckbox" className="searchButton">
          <span role="img" aria-label="search">
            <svg style={{ verticalAlign: 'sub' }} width="30" height="30" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"/></svg>
          </span>
        </label>
        <form onSubmit={submitSearch}>
        {userType === 'admin' && <div className="flex searchModeWrapper"> 
            <button 
              onClick={onSearchTypeChange} 
              className={searchType === 'today' ? 'searchModeButtonActive' : 'searchModeButtonInactive'} 
              type="button"
              value="today"
            >
              Today
            </button>
            <a className="searchModeButtonInactive" href="/yesterday">Yesterday</a>
            {userType === 'superAdmin' && <button 
              onClick={onSearchTypeChange} 
              className={searchType === 'historical' ? 'searchModeButtonActive' : 'searchModeButtonInactive'} 
              type="button"
              value="historical"
            >
              Historical
            </button> }
          </div> }
          
          <button 
            onClick={this.changeSearchDisplay} 
            type="button" 
            className="hidden lg:block searchExit"
          >
            &times;
          </button>
          {searchError && <p>{searchError}</p>}

          {searchType === 'today' && 
          <>
            <label>
              <h2>Search by Pub or Promocode</h2>
              <input
                className="searchInput"
                type="text"
                name="searchInputMain"
                value={searchInputMain}
                onChange={onSearchChange}
              />
            </label>
            <p className="text-center bold">- or -</p>
            <label>
              <h2>Search by Vendor</h2>
              <input
                className="searchInput"
                type="text"
                name="searchInputVender"
                value={searchInputVender}
                onChange={onSearchChange}
              />
            </label>
            <button className="searchSubmitBtn">Submit</button>
          </>
          }
          {searchType === 'historical' && 
            historySearchView
          }
        </form>
        </>
        }
      </nav>
    )
  }
}
