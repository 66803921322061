import React, { useState } from "react";

import LineChart from "../components/LineChart";
import MultiLineChart from "../components/MultiLineChart";

import { 
  formatMoney, 
  formatCountHourlyData, 
  formatACVHourlyData, 
  formatRevHourlyData, 
  formatURL 
} from '../functions/helperFunctions';

export default function Promocode({ 
  error, isLoaded, pcodeData, irisData, acv
}) {
  const [isACVHidden, toggleACV] = useState(false);
  const [isRevHidden, toggleRev] = useState(false); 

  const batch = pcodeData?.reduce((acc, cur) => acc + parseInt(cur['SUM(total)']), 0);
  const orderCount = pcodeData?.reduce((acc, cur) => acc + parseInt(cur['SUM(count)']), 0);

  if (error) {
    return <div className="mx-auto wrapper error"><h2>Error: {error.message}</h2></div>;
  } else if (!isLoaded) {
    return (
      <div className="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  } else {
    return (
      <div className="wrapper promocode">
        <div className="lg:flex">
          <div className="chartWrapper">
            <h2>
              <button 
                type="button"
                onClick={() => toggleRev(prevState => !prevState)} 
                className={isRevHidden ? 'text-gray' : 'underline'}
              >
                Rev
              </button> /  {' '}
              <button 
                type="button"
                onClick={() => toggleACV(prevState => !prevState)} 
                className={isACVHidden ? 'text-gray' : 'underline'}
              >
                ACV
              </button> {' '}
              By Hour
            </h2>
            <MultiLineChart
              values={[formatRevHourlyData(pcodeData), formatACVHourlyData(pcodeData)]}
              dataLabelType="$"
              labels={['Rev', 'ACV']}
              isHidden={[isRevHidden, isACVHidden]}
              id=""
            />
          </div>
          <div className="chartWrapper">
            <h2>Order Count By Hour</h2>
            <LineChart
              values={formatCountHourlyData(pcodeData)}
              dataLabelType=""
              id=""
            />
          </div>
        </div>

        <div className="pcodeInfoWrapper">
          {irisData.effortid && 
            <p>Link <b><a target="_blank" rel="noopener noreferrer" href={`https://apps.pubsvs.com/iris/af/Effort/View/${irisData.effortid}`}>IRIS Effort</a></b></p>
          }
          <p>Link <b><a target="_blank" rel="noopener noreferrer" href={formatURL(pcodeData[0].url)}>Order Form</a></b></p>
          <p>Batch Total <b>{formatMoney(batch)}</b></p>
          <p>Orders <b>{orderCount}</b></p>
          <p>ACV <b>{acv}</b></p>            
          {irisData.campaign && <p>Campaign Name <b>{irisData.campaign}</b></p>}
          {irisData.tree && <p>Tree Name <b>{irisData.tree}</b></p>}
          {irisData.listname && <p>List Name <b>{irisData.listname}</b></p>}
        </div>
      </div>
    )
  }
}

