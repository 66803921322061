import React, { Component } from "react";
import Chart from "chart.js";

import { hourArray, randomColorArray, tooltips, findMaxTicks } from "../functions/chartHelpers";

export default class dailypubrev extends Component {
  chartRef = React.createRef();
  myChart;

  componentDidMount() {
    if (this.props.info) this.buildChart();
  }

  componentDidUpdate() {
    if (this.props.info) this.updateChart();
  }

  formatData = dataSet => {
    // create chart data structure
    const pubs = [...new Set(dataSet.map(datum => datum.pub.toUpperCase()))];
    let chartData = pubs.map((pub, i) => {
      return {
        label: pub,
        data: new Array(24).fill(0),
        backgroundColor: randomColorArray[i],
        borderColor: randomColorArray[i],
       }
    });

    // iterate over raw data, populate chart data
    dataSet.forEach(datum => {
      const pub = datum.pub.toUpperCase();
      const index = chartData.findIndex(entry => entry.label === pub);

      let hour = parseInt(datum.hr);
      hour = (hour < 6) ? hour + 18 : hour - 6;

      chartData[index].data[hour] = datum['SUM(total)'];
    });

    let dataValues = dataSet.map(datum => datum['SUM(total)']);

    if (this.props.filter) {
      chartData = chartData.filter(datum => datum.label === this.props.filter);
      dataValues = chartData[0].data;
    }

    const maxValue = Math.max(...dataValues);
    const maxTicks = findMaxTicks(maxValue);

    const hour = new Date().getHours();
    const labels = [...hourArray];
    labels.length = (hour < 6) ? hour + 18 : hour - 4;

    return { chartData, maxTicks, labels };
  }

  updateChart = () => {
    const { chartData, maxTicks, labels } = this.formatData(this.props.info);

    this.myChart.data.labels = labels;
    this.myChart.data.datasets = chartData;
    this.myChart.options.scales.yAxes[0].ticks.max = maxTicks;

    this.myChart.update();
  }

  buildChart = () => {
    Chart.defaults.global.defaultFontFamily = "sans-serif";

    const myChartRef = this.chartRef.current.getContext("2d");

    let aspectRatio = 2;
    if (window.innerWidth > 830 && this.props.isImprint) {
      aspectRatio = 4
    }

    const { chartData, maxTicks, labels } = this.formatData(this.props.info);

    this.myChart = new Chart(myChartRef, {
      type: "line",
      data: {
        labels: labels,
        datasets: chartData,
      },
      options: {
        animation: false,
        responsive: true,
        aspectRatio,
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: false,
              },
              ticks: {
                max: maxTicks,
                callback: function(value) {
                  return "$" + value;
                },
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Hours Starting From 6 AM",
                fontFamily: "sans-serif",
                fontSize: 14,
              },
            },
          ],
        },
        legend: {
          display: true,
          position: "right",
          labels: {
            boxWidth: 15,
          },
        },
        tooltips,
      },
    });
  };
  render() {
    return <canvas id="pubRevHour" ref={this.chartRef} />;
  }
}
