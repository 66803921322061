import React, { Component } from 'react';

const formatURL = url => {
  const fullURL = decodeURIComponent(url);
  const baseURL = fullURL.slice(0, fullURL.indexOf('?'));
  return baseURL;
}

export default class ordercount extends Component {
  state = {
    sortType: 'day',
    sortDirection: 'descending'
  }
  
  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  });

  formatDate = dateString => {
    const dateArray = dateString.split('-');
    const year = dateArray[0];
    const monthIndex = parseInt(dateArray[1]) - 1;
    const day = dateArray[2];
    return new Date(year, monthIndex, day).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
  }

  setSort = ({ target }) => {
    const sortType = target.getAttribute('data-type');
    const sortDirection = target.getAttribute('data-direction');
    this.setState({ sortType, sortDirection });
  }

  sortFunction = (a, b) => {
    const sortKey = this.state.sortType;
    const sortDirection = this.state.sortDirection;

    if (sortKey === 'baseURL') {
      const x = this.readableURL(formatURL(a.url));
      const y = this.readableURL(formatURL(b.url));
      if (x < y) {
        return sortDirection === 'ascending' ? -1 : 1;
      } else if (x > y) {
        return sortDirection === 'ascending' ? 1 : -1;
      }
    }

    if (sortKey.includes('SUM')) {
      a = parseInt(a[sortKey]);
      b = parseInt(b[sortKey]);
      if (a < b) {
        return sortDirection === 'ascending' ? -1 : 1;
      } else if (a > b) {
        return sortDirection === 'ascending' ? 1 : -1;
      }
    }

    if (a[sortKey] < b[sortKey]) {
      return sortDirection === 'ascending' ? -1 : 1;
    } else if (a[sortKey] > b[sortKey]) {
      return sortDirection === 'ascending' ? 1 : -1;
    }
  };

  readableURL = url => {
    const urlArray = url.split('/');
    if (urlArray[3] === 'journey') {
      return urlArray[4]
    } else {
      return urlArray[3]
    }
  }

  render() {
    const sortedData = [...this.props.info];
    sortedData.sort(this.sortFunction);
    const { sortType, sortDirection } = this.state;

    return (
      <div className="tableWrapper">
        <table id="promocodeList">
          <thead>
            <tr>
              <th>
                <div className="rotated"><span>Date</span></div>
                <div className="buttonWrapper">
                  <button
                    disabled={sortType === 'promocode' && sortDirection === 'ascending'}
                    className="upArrow"
                    data-type="day"
                    data-direction="ascending"
                    onClick={this.setSort}>
                  </button>
                  <button
                    disabled={sortType === 'promocode' && sortDirection === 'descending'}
                    className="downArrow"
                    data-type="day"
                    data-direction="descending"
                    onClick={this.setSort}>
                  </button>
                </div>
              </th>
              <th>
                <div className="rotated"><span>Promocode</span></div>
                <div className="buttonWrapper">
                  <button
                    disabled={sortType === 'promocode' && sortDirection === 'ascending'}
                    className="upArrow"
                    data-type="promocode"
                    data-direction="ascending"
                    onClick={this.setSort}>
                  </button>
                  <button
                    disabled={sortType === 'promocode' && sortDirection === 'descending'}
                    className="downArrow"
                    data-type="promocode"
                    data-direction="descending"
                    onClick={this.setSort}>
                  </button>
                </div>
              </th>
              <th>
                <div className="rotated"><span>Orders</span></div>
                <div className="buttonWrapper">
                  <button
                    disabled={sortType === 'SUM(count)' && sortDirection === 'ascending'}
                    className="upArrow"
                    data-type="SUM(count)"
                    data-direction="ascending"
                    onClick={this.setSort}>
                  </button>
                  <button
                    disabled={sortType === 'SUM(count)' && sortDirection === 'descending'}
                    className="downArrow"
                    data-type="SUM(count)"
                    data-direction="descending"
                    onClick={this.setSort}>
                  </button>
                </div>
              </th>
              <th>
                <div className="rotated"><span>Batch</span></div>
                <div className="buttonWrapper">
                  <button
                    disabled={sortType === 'SUM(total)' && sortDirection === 'ascending'}
                    className="upArrow"
                    data-type="SUM(total)"
                    data-direction="ascending"
                    onClick={this.setSort}>
                  </button>
                  <button
                    disabled={sortType === 'SUM(total)' && sortDirection === 'descending'}
                    className="downArrow"
                    data-type="SUM(total)"
                    data-direction="descending"
                    onClick={this.setSort}>
                  </button>
                </div>
              </th>
              <th className="hidden lg:table-cell">
                <div className="notRotated">Link to OF</div>
                <div className="buttonWrapper">
                  <button
                    disabled={sortType === 'baseURL' && sortDirection === 'ascending'}
                    className="upArrow"
                    data-type="baseURL"
                    data-direction="ascending"
                    onClick={this.setSort}>
                  </button>
                  <button
                    disabled={sortType === 'baseURL' && sortDirection === 'descending'}
                    className="downArrow"
                    data-type="baseURL"
                    data-direction="descending"
                    onClick={this.setSort}>
                  </button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData && sortedData.map((item, i) => (
                <tr key={i}>
                  <td>{this.formatDate(item.day)}</td>
                  <td>
                    <a className="pcodeLink" href={`/promocodes_range/ppp?startdate=${this.props.startDate}&enddate=${this.props.endDate}&promocodes=${item.promocode}`}>
                      {item.promocode}
                    </a>
                  </td>
                  <td>{item['SUM(count)']}</td>
                  <td>{this.formatter.format(item['SUM(total)'])}</td>
                  <td className="hidden lg:table-cell"><a target="_blank" rel="noopener noreferrer" href={formatURL(item.url)}>{this.readableURL(formatURL(item.url))}</a></td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    );
  }
}
