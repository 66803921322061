import React, { Component } from "react";

import BarChart from "../components/BarChart";
import Ordercount from "../components/ordercount";
import LineChart from "../components/LineChart";

class Yesterday extends Component {
  sortEntries = (a, b) => {
    if (a[0] < b[0]) {
      return -1
    }
    if (a[0] > b[0]) {
      return 1 
    }
    return 0;
  }
  formatVendorData = (data) => {
    let values = data.totalByVender.toSorted((a, b) => {
      a = parseInt(a['SUM(total)'])
      b = parseInt(b['SUM(total)'])
      
      if (a < b) {
        return 1
      }
      if (a > b) {
        return -1 
      }
      return 0;
    });
    values.length = 10;
    return values;
  }
  formatACVHourlyData = data => {
    const values = new Array(24).fill(24);
  
    data.forEach(datum => {
      const hour = parseInt(datum.hr);

      if (values[hour] === 24) {
        values[hour] = {};
        values[hour].orders = parseInt(datum['SUM(count)']);
        values[hour].batch = parseInt(datum['SUM(total)']);
      } else {
        values[hour].orders += parseInt(datum['SUM(count)']);
        values[hour].batch += parseInt(datum['SUM(total)']);
      }
    });
  
    return values.map(value => {
      if (value !== 24) {
        return value.batch / value.orders
      } else {
        return 0
      }
    });
  }

  formatCountHourlyData = data => {
    const values = new Array(24).fill(0);
    data.forEach(datum => {
      let hour = parseInt(datum.hr);
  
      values[hour] +=  parseInt(datum['SUM(count)']);
    });
    return values;
  }

  formatACVPubData = (data) => {
    const dataStructure = {};
    data.forEach((datum) => {
      if (
        datum.comment.toUpperCase() !== 'CROSS SELL' &&
        datum.comment.toUpperCase() !== 'CROSSSELL'
      ) {
        const pub = datum.pub.toUpperCase();

        if (dataStructure[pub]) {
          dataStructure[pub].orders += parseInt(datum['SUM(count)']);
          dataStructure[pub].batch += parseInt(datum['SUM(total)']);
        } else {
          dataStructure[pub] = {
            orders: parseInt(datum['SUM(count)']),
            batch: parseInt(datum['SUM(total)']),
          };
        }
      }
    });
    const entries = Object.entries(dataStructure);
    entries.sort(this.sortEntries);

    const labels = entries.map((entry) => entry[0]);
    // parse int for now to avoid nums like 66.66666666
    const values = entries.map((entry) =>
      parseInt(entry[1].batch / entry[1].orders)
    );

    return { labels, values };
  };

  formatBatchPubData = (data) => {
    const dataStructure = {};
    data.forEach((datum) => {
      const pub = datum.pub.toUpperCase();

      if (dataStructure[pub]) {
        dataStructure[pub] += parseInt(datum['SUM(total)']);
      } else {
        dataStructure[pub] = parseInt(datum['SUM(total)']);
      }
    });
    const entries = Object.entries(dataStructure);
    entries.sort(this.sortEntries);

    const labels = entries.map((datum) => datum[0]);
    const values = entries.map((datum) => datum[1]);
    return { labels, values };
  };

  formatRevHourlyData = (data) => {
    const values = new Array(24).fill(0);
    data.forEach(datum => {
      const hour = parseInt(datum.hr);
      values[hour] = datum['SUM(total)']
    });
    return values
  }

  formatCountPubData = (data) => {
    const dataStructure = {};
    data.forEach((datum) => {
      const pub = datum.pub.toUpperCase();

      if (datum['SUM(count)'] !== '0') {
        if (dataStructure[pub]) {
          dataStructure[pub] += parseInt(datum['SUM(count)']);
        } else {
          dataStructure[pub] = parseInt(datum['SUM(count)']);
        }
      }
    });
    const entries = Object.entries(dataStructure);
    entries.sort(this.sortEntries);

    const labels = entries.map((datum) => datum[0]);
    const values = entries.map((datum) => datum[1]);
    return { labels, values };
  };

  render() {
    const { error, data, isLoaded, pcodeData } = this.props;

    if (error) {
      return (
        <div className="mx-auto wrapper error">Error: {error.message}</div>
      );
    } else if (!isLoaded) {
      return (
        <div className="loading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    } else if (this.props.userType !== 'admin') {
      return (
        <div className="mx-auto wrapper error">Error: this is a restricted page</div>
      );
    } else {
      return (
        <div className="wrapper home">
         
            <div className="chartWrapper">
              <h2>Daily Batch Revenue By Hour</h2>
              <LineChart
                values={this.formatRevHourlyData(data.totalByHour)}
                dataLabelType="$"
                id="pubRev"
                aspectRatio="4"
                yesterday="true"
              />
            </div>
            <div className="chartWrapper">
              <h2>Daily Batch Revenue By Pub</h2>
              <BarChart
                values={this.formatBatchPubData(data.totalPcode).values}
                labels={this.formatBatchPubData(data.totalPcode).labels}
                xAxesLabel=""
                dataLabelType="$"
                aspectRatio="4"
              />
            </div>
      
            <div className="chartWrapper">
              <h2>ACV By Pub</h2>
              <BarChart
                values={this.formatACVPubData(data.totalPcode).values}
                labels={this.formatACVPubData(data.totalPcode).labels}
                xAxesLabel=""
                dataLabelType="$"
                aspectRatio="4"
              />
            </div>
            <div className="chartWrapper">
              <h2>ACV By Hour</h2>
              <LineChart
                values={this.formatACVHourlyData(data.pcodeByHour)}
                dataLabelType="$"
                id=""
                aspectRatio="4"
                yesterday="true"
              />
            </div>
         
            <div className="chartWrapper">
              <h2>Order Count By Hour</h2>
              <LineChart
                values={this.formatCountHourlyData(data.pcodeByHour)}
                dataLabelType=""
                id=""
                aspectRatio="4"
                yesterday="true"
              />
            </div>{" "}
            <div className="chartWrapper">
              <h2>Order Count By Pub</h2>
              <BarChart
                values={this.formatCountPubData(data.totalPcode).values}
                labels={this.formatCountPubData(data.totalPcode).labels}
                xAxesLabel=""
                dataLabelType=""
                aspectRatio="4"
              />
            </div>
            <div className="chartWrapper">
              <h2>Top 10 Batch by Vendor</h2>
              <BarChart
                labels={this.formatVendorData(data).map(item => item.listname)}
                values={this.formatVendorData(data).map(item => item['SUM(total)'])}
                xAxesLabel=""
                dataLabelType=""
                aspectRatio="4"
              />
            </div>
          
          <h2 className="mb-0">Orders By Promocode</h2>
          <Ordercount page="yesterday" info={pcodeData} />
        </div>
      );
    }
  }
}
export default Yesterday;
