export const findMaxTicks = maxValue => {
  let maxTicks = maxValue * 1.175;
  const divider = maxTicks < 200 ? 10 : 100;

  maxTicks = Math.round(maxTicks / divider);
  maxTicks *= divider

  if (maxTicks <= maxValue) maxTicks = maxValue + 4

  return maxTicks
}

export const randomColorArray = [
  "rgba(6, 189, 149,0.6)",
  "rgba(215, 164, 140,0.6)",
  "rgba(108, 153, 152,0.6)",
  "rgba(78, 95, 168,0.6)",
  "rgba(193, 226, 232,0.6)",
  "rgba(211, 122, 251,0.6)",
  "rgba(201, 3, 57,0.6)",
  "rgba(90, 68, 139,0.6)",
  "rgba(106, 119, 85,0.6)",
  "rgba(55, 19, 88,0.6)",

  "rgba(68, 37, 61,0.6)",
  "rgba(219, 245, 120,0.6)",
  "rgba(141, 31, 92,0.6)",
  "rgba(101, 49, 159,0.6)",
  "rgba(37, 67, 138,0.6)",
  "rgba(18, 112, 223,0.6)",
  "rgba(113, 189, 135,0.6)",
  "rgba(62, 157, 174,0.6)",
  "rgba(178, 24, 87,0.6)",
  "rgba(251, 97, 145,0.6)",

  "rgba(10, 41, 205,0.6)",
  "rgba(219, 200, 80,0.6)",
  "rgba(0, 204, 217,0.6)",
  "rgba(79, 94, 135,0.6)",
  "rgba(241, 53, 84,0.6)",
  "rgba(156, 212, 202,0.6)",
  "rgba(154, 66, 66,0.6)",
  "rgba(13, 77, 60,0.6)",
  "rgba(133, 19, 49,0.6)",
  "rgba(170, 208, 142,0.6)",
  "rgba(126, 177, 195,0.6)",
  "rgba(174, 113, 99,0.6)",
  "rgba(120, 138, 229,0.6)",
  "rgba(228, 204, 159,0.6)",
  "rgba(131, 98, 97,0.6)"
];

export const hourArray = [
  "6am",
  "7am",
  "8am",
  "9am",
  "10am",
  "11am",
  "12pm",
  "1pm",
  "2pm",
  "3pm",
  "4pm",
  "5pm",
  "6pm",
  "7pm",
  "8pm",
  "9pm",
  "10pm",
  "11pm",
  "12am",
  "1am",
  "2am",
  "3am",
  "4am",
  "5am"
];

export const yesterdayHourArray = [
  "12am",
  "1am",
  "2am",
  "3am",
  "4am",
  "5am",
  "6am",
  "7am",
  "8am",
  "9am",
  "10am",
  "11am",
  "12pm",
  "1pm",
  "2pm",
  "3pm",
  "4pm",
  "5pm",
  "6pm",
  "7pm",
  "8pm",
  "9pm",
  "10pm",
  "11pm"
];

export const tooltips = {
  enabled: true,
  bevelWidth: 3,
  bevelHighlightColor: "rgba(255, 255, 255, 0.75)",
  bevelShadowColor: "rgba(0, 0, 0, 0.5)",
  shadowOffsetX: 3,
  shadowOffsetY: 3,
  shadowBlur: 10,
  titleFontSize: 18,
  bodyFontSize: 18,
  shadowColor: "rgba(0, 0, 0, 0.5)"
};
