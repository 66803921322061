import React, { Component } from "react";
import Chart from "chart.js";

import { hourArray, randomColorArray, tooltips, findMaxTicks } from "../functions/chartHelpers";

export default class MultiLineChart extends Component {
  chartRef = React.createRef();
  myChart;

  componentDidMount() {
    if (this.props.values) this.buildChart();
  }

  componentDidUpdate() {
    if (this.props.values) this.updateChart();
  }

  formatData = () => {
    return this.props.values.map((value, i) => {
      return {
        label: this.props.labels[i],
        data: value,
        backgroundColor: randomColorArray[i],
        borderColor: randomColorArray[i]
      }
    });
  }

  updateChart = () => {
    const maxValue = Math.max(...this.props.values.flat());
    const maxTicks = findMaxTicks(maxValue);

    const labels = [...hourArray];
    const hour = new Date().getHours();
    labels.length = (hour < 6) ? hour + 18 : hour - 4;

    this.myChart.data.labels = labels;
    this.myChart.data.datasets = this.formatData();
    this.myChart.options.scales.yAxes[0].ticks.suggestedMax = maxTicks;

    this.myChart.data.datasets.forEach((set, i) => {
      const meta = this.myChart.getDatasetMeta(i);
      meta.hidden = this.props.isHidden[i];
    });

    this.myChart.update();
  }

  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext("2d");

    Chart.defaults.global.defaultFontFamily = "sans-serif";

    const maxValue = Math.max(...this.props.values.flat());
    const maxTicks = findMaxTicks(maxValue);

    const labels = [...hourArray];
    const hour = new Date().getHours();
    labels.length = (hour < 6) ? hour + 18 : hour - 4;

    this.myChart = new Chart(myChartRef, {
      type: "line", //bar, horizontalBar, pie, line, doughnut, radar, polarArea
      data: {
        labels,
        datasets: this.formatData()
      },
      options: {
        animation: false,
        responsive: true,
        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMax: maxTicks,
                callback: value => this.props.dataLabelType + value
              }
            }
          ]
        },
        legend: {
          display: false
        },
        tooltips
      }
    });
  };

  render() {
    return <canvas id={this.props.id} ref={this.chartRef} />;
  }
}
