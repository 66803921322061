import React, { Component } from "react";

import BarChart from "../components/BarChart";
import Ordercount from "../components/ordercount";
import LineChart from "../components/LineChart";
import Pubrevhourly from "../components/pubRevHourly";

import {
  formatRevHourlyData,
  formatACVHourlyData,
  formatCountHourlyData,
} from "../functions/helperFunctions";

class Home extends Component {
  sortEntries = (a, b) => {
    if (a[0] < b[0]) {
      return -1
    }
    if (a[0] > b[0]) {
      return 1 
    }
    return 0;
  }
  formatACVPubData = (data) => {
    const dataStructure = {};
    data.forEach((datum) => {
      if (
        datum.comment.toUpperCase() !== "CROSS SELL" &&
        datum.comment.toUpperCase() !== "CROSSSELL"
      ) {
        const pub = datum.pub.toUpperCase();

        if (dataStructure[pub]) {
          dataStructure[pub].orders += parseInt(datum["SUM(count)"]);
          dataStructure[pub].batch += parseInt(datum["SUM(total)"]);
        } else {
          dataStructure[pub] = {
            orders: parseInt(datum["SUM(count)"]),
            batch: parseInt(datum["SUM(total)"]),
          };
        }
      }
    });
    const entries = Object.entries(dataStructure);
    entries.sort(this.sortEntries);

    const labels = entries.map((entry) => entry[0]);
    // parse int for now to avoid nums like 66.66666666
    const values = entries.map((entry) =>
      parseInt(entry[1].batch / entry[1].orders)
    );

    return { labels, values };
  };

  formatBatchPubData = (data) => {
    const dataStructure = {};
    data.forEach((datum) => {
      const pub = datum.pub.toUpperCase();

      if (dataStructure[pub]) {
        dataStructure[pub] += parseInt(datum["SUM(total)"]);
      } else {
        dataStructure[pub] = parseInt(datum["SUM(total)"]);
      }
    });
    const entries = Object.entries(dataStructure);
    entries.sort(this.sortEntries);

    const labels = entries.map((datum) => datum[0]);
    const values = entries.map((datum) => datum[1]);
    return { labels, values };
  };

  formatCountPubData = (data) => {
    const dataStructure = {};
    data.forEach((datum) => {
      const pub = datum.pub.toUpperCase();

      if (datum["SUM(count)"] !== "0") {
        if (dataStructure[pub]) {
          dataStructure[pub] += parseInt(datum["SUM(count)"]);
        } else {
          dataStructure[pub] = parseInt(datum["SUM(count)"]);
        }
      }
    });
    const entries = Object.entries(dataStructure);
    entries.sort(this.sortEntries);

    const labels = entries.map((datum) => datum[0]);
    const values = entries.map((datum) => datum[1]);
    return { labels, values };
  };

  render() {
    const { error, data, isLoaded, pcodeData } = this.props;
    if (error) {
      return (
        <div className="mx-auto wrapper error">Error: {error.message}</div>
      );
    } else if (!isLoaded) {
      return (
        <div className="loading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    } else {
      return (
        <div className="wrapper home">
          <div className="lg:flex">
            <div className="chartWrapper">
              <h2>Daily Batch Revenue By Hour</h2>
              <LineChart
                values={formatRevHourlyData(data.totalByHour)}
                dataLabelType="$"
                id="pubRev"
              />
            </div>
            <div className="chartWrapper">
              <h2>Daily Batch Revenue By Pub</h2>
              <BarChart
                values={this.formatBatchPubData(data.totalPcode).values}
                labels={this.formatBatchPubData(data.totalPcode).labels}
                xAxesLabel=""
                dataLabelType="$"
              />
            </div>
          </div>

          <div className="chartWrapper hidden lg:block">
            <h2>Daily Batch Revenue By Pub Per Hour</h2>
            <Pubrevhourly isImprint={true} info={data.hourlyPub} />
          </div>

          <div className="lg:flex">
            <div className="chartWrapper">
              <h2>ACV By Pub</h2>
              <BarChart
                values={this.formatACVPubData(data.totalPcode).values}
                labels={this.formatACVPubData(data.totalPcode).labels}
                xAxesLabel=""
                dataLabelType="$"
              />
            </div>
            <div className="chartWrapper">
              <h2>ACV By Hour</h2>
              <LineChart
                values={formatACVHourlyData(data.pcodeByHour)}
                dataLabelType="$"
                id=""
              />
            </div>
          </div>
          <div className="lg:flex">
            <div className="chartWrapper">
              <h2>Order Count By Hour</h2>
              <LineChart
                values={formatCountHourlyData(data.pcodeByHour)}
                dataLabelType=""
                id=""
              />
            </div>{" "}
            <div className="chartWrapper">
              <h2>Order Count By Pub</h2>
              <BarChart
                values={this.formatCountPubData(data.totalPcode).values}
                labels={this.formatCountPubData(data.totalPcode).labels}
                xAxesLabel=""
                dataLabelType=""
              />
            </div>
          </div>
          <h2 className="mb-0">Orders By Promocode</h2>
          <Ordercount info={pcodeData} />
        </div>
      );
    }
  }
}
export default Home;
